import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import jspreadsheet from 'jspreadsheet';
import { commonConstants } from '../../../CONSTANTS/tpi-common-constants';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
@Component({
    selector: 'tpi-bulk-grid',
    templateUrl: './bulk-grid.component.html',
    styleUrls: ['./bulk-grid.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: BulkGridComponent
    }]
})
export class BulkGridComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
    @Input() parentForm: FormGroup;
    @Input() resetGrid = false;
    @Output() resetGridChange: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('spreadsheet') spreadsheet!: ElementRef;

    data: Array<Array<string>> = [];
    gridObject: jspreadsheet.worksheetInstance[];
    gridValueElement: Array<Array<string>> = [];
    set gridValue(value) {
        this.gridValueElement = value;
    }

    constructor(private loggerService: LoggerService) {
    }

    buildGrid(): void {
        jspreadsheet.setLicense(commonConstants.BULK_CONSTANTS.JSPREADSHEET_TOKEN);
        const componentLevel = this.currentComponent();
        try {
            this.gridObject = jspreadsheet(this.spreadsheet.nativeElement, {
                worksheets: [{
                    virtualizationY: false,
                    allowInsertRow: false,
                    allowInsertColumn: false,
                    defaultColAlign: 'left',
                    data: this.parentForm.get('grid').value || this.data,
                    columns: [{
                        type: 'text',
                        title: commonConstants.BULK_CONSTANTS.LOAN_NUMBER_TITLE,
                        tooltip: 'Title',
                        width: 210,
                        align: 'left',
                        autoCasting: false
                    }, {
                        type: 'text',
                        title: commonConstants.BULK_CONSTANTS.POLICY_NUMBER_TITLE,
                        width: 360,
                        align: 'left',
                        autoCasting: false
                    }, {
                        type: 'text',
                        title: commonConstants.BULK_CONSTANTS.ZIPCODE_TITLE,
                        width: 200,
                        align: 'left',
                        autoCasting: false
                    }]
                }
                ],
                // eslint-disable-next-line max-params
                onchange(gridObject, cell, x, y) {
                    cell.innerHTML = cell.innerHTML.replace(/\s/g, '');
                    componentLevel.onChange(gridObject.getData());
                    componentLevel.onTouched(gridObject.getData());
                },
                onafterchanges(worksheet: jspreadsheet.worksheetInstance, records: Array<any>) {
                    for (const record of records) {
                        const x: number = +record.x;
                        const y: number = +record.y;
                        const cell = worksheet.getCellFromCoords(x, y);
                        componentLevel.updateCellStyle(cell, x, y);
                    }
                },
                // eslint-disable-next-line max-params
                onselection(worksheet: jspreadsheet.worksheetInstance, px: number, py: number, ux: number, uy: number, origin?: object) {
                    const gridData = Array.from(document.getElementsByTagName('td'));
                    for (const td of gridData) {
                        if (td.getAttribute('data-x') === '0' && px === 0) {
                            const divElements: HTMLCollectionOf<Element> = document.getElementsByClassName('jss_input');
                            const divElement: Element = divElements[0];
                            if (divElement) {
                                divElement.classList.add('nwpii');
                            }
                        }
                    }
                },
                contextMenu() {
                    return false;
                }
            });
        } catch (exception) {
            this.data = [];
            this.loggerService.error('Unable to load jspreadsheet', exception);
        }
    }
    currentComponent(): BulkGridComponent {
        return this;
    }

    ngAfterViewInit(): void {
        if (this.parentForm.get('grid').value > 0) {
            this.data = this.parentForm.get('grid').value;
        } else {
            this.resetData();
        }
        this.buildGrid();
        const gridData = Array.from(document.getElementsByTagName('td'));
        for (const td of gridData) {
            if (td.getAttribute('data-x') === '0') {
                td.classList.add('nwpii');
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resetGrid?.currentValue) {
            this.reset();
            this.gridObject[0].loadData(this.parentForm.get('grid').value);
            this.resetGridChange.emit(false);
        }
    }

    onChange = (value: any): any => {
        // This is intentional
    };

    onTouched = (value: any): any => {
        // This is intentional
    };

    registerOnChange(registerFunction: any): void {
        this.onChange = registerFunction;
    }

    registerOnTouched(touchedFunction: any): void {
        this.onTouched = touchedFunction;
    }

    reset(): void {
        this.resetData();
    }

    resetData(): void {
        if (this.parentForm.get('grid').value) {
            this.data = this.parentForm.get('grid').value;
        } else {
            for (let index = 0; index < commonConstants.BULK_CONSTANTS.MAX_UPLOAD; index++) {
                this.data.push(['', '', '']);
            }
        }
    }

    setCellAttribute(cell: any, options: {
        x: number;
        y: number;
        bulkErrorField: any;
        bulkError: any;
    }): void {
        if ((options.bulkError[options.bulkErrorField[1]]?.required || options.bulkError[options.bulkErrorField[1]]?.format) &&
            options.x.toString() === options.bulkErrorField[0] &&
            options.y.toString() === (options.bulkError.rowNo - 1).toString()) {
            cell.setAttribute(commonConstants.BULK_CONSTANTS.STYLE_CONSTANTS.PROP_STYLE,
                commonConstants.BULK_CONSTANTS.STYLE_CONSTANTS.ERROR_STYLE);
        }
    }

    setDisabledState(isDisabled: boolean): void {
        // Intentionally left
    }

    updateCellStyle(cell: HTMLElement, x: number, y: number): void {
        cell.setAttribute(commonConstants.BULK_CONSTANTS.STYLE_CONSTANTS.PROP_STYLE,
            commonConstants.BULK_CONSTANTS.STYLE_CONSTANTS.ERROR_RESET);
        const bulkErrorObject = this.parentForm.get('grid').errors;
        if (this.parentForm?.get('grid')?.errors && bulkErrorObject?.bulkError?.length > 0) {
            const bulkErrorFields = [['0', 'loanNumber'], ['1', 'policyNumber'], ['2', 'zipCode']];
            for (const bulkError of bulkErrorObject.bulkError) {
                for (const bulkErrorField of bulkErrorFields) {
                    this.setCellAttribute(cell, {
                        x, y, bulkErrorField, bulkError
                    });
                }
            }
        }
    }

    writeValue(gridValueObject: any): void {
        this.gridValue = gridValueObject;
    }
}

